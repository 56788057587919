<template>
  <div class="srch">

    <picture>
      <source media="(max-width: 499px)" srcset="/images/feed/search-2x.webp" type="image/webp">
      <source media="(min-width: 500px)" srcset="/images/feed/search.webp" type="image/webp">
      <img 
        class="simg" 
        data-not-lazy
        fetchpriority=”high”
        data-ratio="406-300" 
        alt="Growdiaries" 
        src="/images/feed/search.webp"
      />
    </picture>
 
    <div class="srch_row">
      <label for="main_search">Strains, Breeders, Nutrients</label>

      <div :class="{'placeholder': true, 'hidden': activeSearch}">
        Search among
        <GeneralOdometer 
          v-if="count_data"
          :start="count_data"          
        />
          diaries
      </div>


      <TopbarSearch 
        placeholder=""
        @setActive="activeSearch = $event;"
      />

    </div>

  </div>
</template>

<script setup lang="ts">

import { useNuxtApp, useLazyAsyncData } from '#app';
 

const { $api } = useNuxtApp();

const timeout = ref<any>(null);
const activeSearch = ref<boolean>(false);
const placeholder = ref<string>('Search among diaries');
const placeholderTpl = ref<string>('Search among #cnt# diaries');

const createNewTimeout = function() {
  const time = 5000;
  timeout.value = setTimeout(() => {
    refreshData();
  }, time);
};

const clearNewTimeout = function() {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
};

const loadData = async function() {
  const response = await $api.getDiariesTotalCount();
  return response;
};

const { status: statusData, data: count_data, refresh: refreshData } = await useLazyAsyncData('count_data', async () => await loadData());

onMounted(() => {
  createNewTimeout();
});

onBeforeMount(() => {
  clearNewTimeout();
});
</script>
   



<style scoped>

.srch {
  display: none;
}

.srch .main_search.focus input::-webkit-input-placeholder {
  color: rgb(216 216 216 / 87%) !important;
}

.srch .main_search.focus input::-moz-placeholder {
  color: rgb(216 216 216 / 87%) !important;
}

.srch .main_search.focus input:-ms-input-placeholder {
  color: rgb(216 216 216 / 87%) !important;
}


@media (max-width: 768px) {


  .srch {
    display: block;
    width: calc(100%);
    position: relative;
    margin-top: -70px;
    background-color: #061112;
    height: 340px;

    display: flex;
    justify-content: center;

  }

  .srch picture {
    width: 100%;
  }
  .srch .simg {
    object-fit: cover;
    width: 100%;
    height: 340px;
  }

  .srch .main_search {
    /* position: absolute; */
    /* top: 40%; */
    text-align: center;
    /* max-width: 70%!important; */
    width: 100%;
  }

  .srch .main_search .input {
    max-width: 100% !important;
    width: 100%;
  }

  .srch .main_search .search input {
    width: 100%;
  }

  .srch .srch_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    padding: 0 10%;
  }

  .srch .srch_row label {
    font-size: 1.1rem;
    color: white;
    font-weight: 600;

    text-shadow: 0 0 5px #0000009e;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }


  .srch .placeholder{
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    top: 46px;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #818181;
    width: 71%;
    pointer-events: none;
    max-width: 350px;
  }


}



</style>

<style>

@media (max-width: 768px) {
  /* topbar */

  html body .bd:not(.onnav):not(.scrolled)[data-section="home"] .topbar {
    background-color: transparent;
    /* position: fixed; */
  }


  html body .bd:not(.onnav):not(.scrolled)[data-section="home"][theme="light"] .topbar .logo {
    filter: brightness(0) invert(1);
  }

  html body .bd:not(.onnav):not(.scrolled)[data-section="home"] .topbar .sdbr {
    color: white;
  }

  html body:not(.show_sidebar) .bd:not(.scrolled)[data-section="home"] .topbar .context .lnk,
  html body .bd:not(.onnav):not(.scrolled)[data-section="home"] .topbar .context {
    color: white;
  }

  /* html .header_add {
    display: none;
  } */

  /*  */

}
</style>